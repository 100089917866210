<template>
  <div class="container">
    <el-row class="banner" ref="bannerPic">
      <img v-lazy="banner.image" alt="" />
    </el-row>
    <el-row class="main_content">
      <el-col class="recruit_title">{{ $t('lang.recruit') }}</el-col>
      <el-col class="recruit_info">{{ $t('lang.recruit_info') }}</el-col>
      <el-col class="recruit_require">{{
        $t('lang.recruit_position1')
      }}</el-col>
      <el-col class="recruit_require">{{
        $t('lang.recruit_condition1')
      }}</el-col>
      <el-col class="recruit_require">{{
        $t('lang.work_content1')
      }}</el-col>
      <el-col class="recruit_require">{{
        $t('lang.recruit_position2')
      }}</el-col>
      <el-col class="recruit_require">{{
        $t('lang.recruit_condition2')
      }}</el-col>
      <el-col class="recruit_require">{{
        $t('lang.work_content2')
      }}</el-col>
      <el-col class="recruit_require">{{
        $t('lang.work_hours')
      }}</el-col>
      <el-col>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-row class="mt">
            <el-col :span="11" class="hidden-xs-only" v-if="language == 'cn' || language == 'jp'">
              <el-form-item :label="$t('lang.name')" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="hidden-xs-only" v-if="language == 'en'">
              <el-form-item label="First Name" prop="firstName">
                <el-input v-model="ruleForm.firstName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up" v-if="language == 'cn' || language == 'jp'">
              <el-form-item :label="$t('lang.name')" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up" v-if="language == 'en'">
              <el-form-item label="First Name" prop="firstName">
                <el-input v-model="ruleForm.firstName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="hidden-xs-only">&nbsp;</el-col>
            <el-col :span="11" class="hidden-xs-only" v-if="language == 'cn' || language == 'jp'">
              <el-form-item :label="$t('lang.alphabet')" prop="name_pinyin">
                <el-input v-model="ruleForm.name_pinyin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="hidden-xs-only" v-if="language == 'en'">
              <el-form-item label="Last Name" prop="lastName">
                <el-input v-model="ruleForm.lastName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up" v-if="language == 'cn' || language == 'jp'">
              <el-form-item :label="$t('lang.alphabet')" prop="name_pinyin">
                <el-input v-model="ruleForm.name_pinyin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up" v-if="language == 'en'">
              <el-form-item label="Last Name" prop="lastName">
                <el-input v-model="ruleForm.lastName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt">
            <el-col :span="11" class="hidden-xs-only">
              <el-form-item :label="$t('lang.age')" prop="age">
                <el-input v-model="ruleForm.age"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up">
              <el-form-item :label="$t('lang.age')" prop="age">
                <el-input v-model="ruleForm.age"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="hidden-xs-only">&nbsp;</el-col>
            <el-col :span="11" class="hidden-xs-only">
              <el-form-item :label="$t('lang.gender')" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio label="1">{{ $t('lang.man') }}</el-radio>
                  <el-radio label="2">{{ $t('lang.woman') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up">
              <el-form-item :label="$t('lang.gender')" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio label="1">{{ $t('lang.man') }}</el-radio>
                  <el-radio label="2">{{ $t('lang.woman') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt">
            <el-col :span="11" class="hidden-xs-only">
              <el-form-item :label="$t('lang.phone')" prop="phone">
                <el-select clearable @input="selectChange" v-model="countryValue" class="selectBox" :placeholder="$t('lang.selectCountry')">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="language=='cn'?`${item.name} (+${item.tel})`: language=='jp'?`${item.jp} (+${item.tel})`:language=='en'?`${item.en} (+${item.tel})`: ''"
                    :value="item.tel"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model="ruleForm.phone"
                  class="inputBox"
                  @change="mobileChange"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up">
              <el-form-item :label="$t('lang.phone')" prop="phone">
                <el-select clearable @input="selectChange" v-model="countryValue" class="selectBox" :placeholder="$t('lang.selectCountry')">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="language=='cn'?`${item.name} (+${item.tel})`: language=='jp'?`${item.jp} (+${item.tel})`:language=='en'?`${item.en} (+${item.tel})`: ''"
                    :value="item.tel"
                  >
                  </el-option>
                </el-select>
                 <el-input
                  v-model="ruleForm.phone"
                  class="inputBox"
                  @change="mobileChange"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="hidden-xs-only">&nbsp;</el-col>
            <el-col :span="11" class="hidden-xs-only">
              <el-form-item :label="$t('lang.email')" prop="email">
                <el-input v-model="ruleForm.email"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="hidden-sm-and-up">
              <el-form-item :label="$t('lang.email')" prop="email">
                <el-input v-model="ruleForm.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt">
            <el-col :span="24">
              <el-form-item :label="$t('lang.education')" prop="education">
                <el-input v-model="ruleForm.education"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt">
            <el-col :span="24">
              <el-form-item :label="$t('lang.work_experience')" prop="work">
                <el-input
                  type="textarea"
                  v-model="ruleForm.work"
                  :autosize="{ minRows: 4, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mt">
            <el-col :span="24">
              <el-form-item :label="$t('lang.self_introduce')" prop="profile">
                <el-input
                  type="textarea"
                  v-model="ruleForm.profile"
                  :autosize="{ minRows: 6, maxRows: 6 }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="btn">
            <el-col>
              <el-form-item>
                <el-button
                  class="confirm_btn"
                  type="primary"
                  @click="addForm('ruleForm')"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  >{{ $t('lang.application') }}</el-button
                >
                <!-- <el-button class="reset_btn" @click="resetForm('ruleForm')"
                  >重置</el-button
                > -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { joinAdd, homeBanner } from '../api/index'
export default {
  name: 'recruit',
  props: {},
  data() {
    var validateName = (rule, value, callback) => {
      // const reg = /^([\u4e00-\u9fa5]{1,20}|[a-zA-Z\.\s]{1,20})$/
      if (value) {
        callback()
      } else {
        callback(new Error(this.$t('lang.warn_name')))
      }
    }
    var validateAlphabet = (rule, value, callback) => {
      // const reg = /^[a-zA-Z]+$/
      if (value) {
        callback()
      } else {
        callback(new Error(this.$t('lang.warn_alphabet')))
      }
    }
    var validateAge = (rule, value, callback) => {
      const reg = /^[0-9]*$/
      if (value) {
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.warn_age_info')))
        } else {
          callback()
        }
      } else {
        callback(new Error(this.$t('lang.warn_age')))
      }
    }

    var validatePhone = (rule, value, callback) => {
      if (value) {
        callback()
      } else {
        callback(new Error(this.$t('lang.warn_phone')))
      }
    }

    var validateEmail = (rule, value, callback) => {
      var reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
      if (value) {
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.warn_email_info')))
        } else {
          callback()
        }
      } else {
        callback(new Error(this.$t('lang.warn_email')))
      }
    }
    var validateGender = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('lang.warn_gender')))
      } else {
        callback()
      }
    }
    return {
      banner: {},
      countryValue: '',
      language: window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp',
      fullscreenLoading: false,
      ruleForm: {
        type: 1,
        name: '',
        name_pinyin: '',
        firstName: '',
        lastName: '',
        age: '',
        sex: '1',
        mobile: '',
        phone: '',
        email: '',
        education: '',
        work: '',
        profile: ''
      },
      rules: {
        name: [{ required: true, validator: validateName, trigger: 'blur' }],
        name_pinyin: [
          { required: true, validator: validateAlphabet, trigger: 'blur' }
        ],
        firstName: [
          { required: true, validator: validateName, trigger: 'blur' }
        ],
        lastName: [
          { required: true, validator: validateName, trigger: 'blur' }
        ],
        age: [{ required: true, validator: validateAge, trigger: 'blur' }],
        sex: [
          {
            required: true,
            validator: validateGender,
            trigger: ['blur', 'change']
          }
        ],
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }]
      },
      options: [
        {
          short: 'CN',
          name: '中国',
          en: 'China',
          jp: '中国',
          tel: '86'
        },
        {
          short: 'HK',
          name: '中国香港',
          en: 'Hongkong',
          jp: '香港',
          tel: '852'
        },
        {
          short: 'MO',
          name: '中国澳门',
          en: 'Macao',
          jp: 'マカオ',
          tel: '853'
        },
        {
          short: 'US',
          name: '美国',
          en: 'UnitedStatesofAmerica',
          jp: 'アメリカ',
          tel: '001'
        },
        {
          short: 'JP',
          name: '日本',
          en: 'Japan',
          jp: '日本',
          tel: '81'
        },
        {
          short: 'CA',
          name: '加拿大',
          en: 'Canada',
          jp: 'カナダ',
          tel: '1'
        },
        {
          short: 'GB',
          name: '英国',
          en: 'UnitedKingdom',
          jp: 'イギリス',
          tel: '44'
        },
        {
          short: 'DE',
          name: '德国',
          en: 'Germany',
          jp: 'ドイツ',
          tel: '49'
        },
        {
          short: 'FR',
          name: '法国',
          en: 'France',
          jp: 'フランス',
          tel: '33'
        },
        {
          short: 'IT',
          name: '意大利',
          en: 'Italy',
          jp: 'イタリア',
          tel: '39'
        },
        {
          short: 'ES',
          name: '西班牙',
          en: 'Spain',
          jp: 'スペイン',
          tel: '34'
        },
        {
          short: 'VN',
          name: '越南',
          en: 'Vietnam',
          jp: 'ベトナム',
          tel: '84'
        },
        {
          short: 'IN',
          name: '印度',
          en: 'India',
          jp: 'インド',
          tel: '91'
        },
        {
          short: 'KR',
          name: '韩国',
          en: 'Korea',
          jp: '韓国',
          tel: '82'
        },
        {
          short: 'BR',
          name: '巴西',
          en: 'Brazil',
          jp: 'ブラジル',
          tel: '55'
        },
        {
          short: 'AR',
          name: '阿根廷',
          en: 'Argentina',
          jp: 'アルゼンチン',
          tel: '54'
        },
        {
          short: 'TW',
          name: '中国台湾',
          en: 'Taiwan',
          jp: '台湾',
          tel: '886'
        }
      ]

    }
  },
  computed: {},
  created() {
    this.getBanner()
  },
  mounted() {
     var that = this
    that.picChange()
    window.onresize = () => {
      return (() => {
        that.picChange()
      })()
    }
  },
  destroyed() {
    window.onresize = null
  },
  watch: {},
  methods: {
    picChange() {
      var banner = this.$refs.bannerPic
      banner.$el.style.width = document.body.clientWidth + 'px'
      banner.$el.style.height = document.body.clientWidth / 4 + 'px'
    },
    getBanner() {
      homeBanner({ type: 4 })
        .then(res => {
          if (res.code == '20000') {
            this.banner = res.data.list[0]
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    selectChange(value) {
      console.log(value)
    },
    mobileChange() {},
    addForm(formName) {
       if (this.language == 'en') {
        this.ruleForm.name = this.ruleForm.lastName + '.' + this.ruleForm.firstName
      }
      this.ruleForm.mobile = '(' + this.countryValue + ')' + this.ruleForm.phone
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.fullscreenLoading = true
          joinAdd(this.ruleForm)
            .then(res => {
              if (res.code == '20000') {
                this.fullscreenLoading = false
                this.$message({
                  showClose: true,
                  message: this.language =='cn'?'信息已发送': this.language =='jp'? '送信成功': this.language == 'en'?'Message sent': '',
                  type: 'success'
                })
                this.$refs[formName].resetFields()
              }
            })
            .catch(err => {
              this.$message({
                showClose: true,
                message: this.language =='cn'?'信息发送失败': this.language =='jp'? '送信失败': this.language == 'en'?'Message sending failed': '',
                type: 'error'
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .mt {
    margin-top: 47px;
  }
  .container {
    width: 100%;
    .banner {
      // width: 100%;
      // height: 581px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .main_content {
      width: 60%;
      margin: 0 auto;
      //   background-color: red;
      .recruit_title {
        margin: 76px 0 32px 0;
        color: #000000;
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 2.25px;
        line-height: 38px;
      }
      .recruit_info {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1.13px;
        line-height: 38px;
      }
      .recruit_require {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1.13px;
        line-height: 38px;
      }
      .confirm_btn {
        width: 120px;
        border-radius: 7px;
        background-color: #bc3810;
        .span {
          color: #ffffff;
          font-size: 16px;
          letter-spacing: 0;
        }
      }
      .btn {
        margin: 54px 0 162px 0;
      }
      .reset_btn {
        height: 34px;
        width: 120px;
        border-radius: 7px;
        background-color: #dcdfe6;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .mt {
    margin-top: 11px;
  }
  .container {
    width: 100%;
    .banner {
      // width: 100%;
      // height: 147px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .main_content {
      width: 76%;
      margin: 0 auto;
      //   background-color: red;
      .recruit_title {
        margin: 16px 0 32px 0;
        height: 38px;
        color: #000000;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 38px;
      }
      .recruit_info {
        height: 38px;
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.75px;
        line-height: 38px;
      }
      .recruit_require {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        margin-bottom: 20px;
      }
      .confirm_btn {
        width: 120px;
        border-radius: 7px;
        background-color: #bc3810;
        .span {
          color: #ffffff;
          font-size: 16px;
          letter-spacing: 0;
        }
      }
      .btn {
        margin: 20px 0 53px 0;
      }
      .reset_btn {
        height: 34px;
        width: 120px;
        border-radius: 7px;
        background-color: #dcdfe6;
      }
    }
  }
}
</style>
